import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

const AboutUs = () =>
    import ('@/views/AboutUs.vue')
const Products = () =>
    import ('@/views/Products.vue')
const CustomizedPrints = () =>
    import ('@/views/CustomizedPrints.vue')
// const News = () =>
//     import ('@/views/News.vue')
// const NewsDetail = () =>
//     import ('@/views/NewsDetail.vue')
const Certification = () =>
    import ('@/views/Certification.vue')
    
const ContactUs = () =>
    import ('@/views/ContactUs.vue')

const GetFreeSamples = () =>
    import ('@/views/GetFreeSamples.vue')

const GetFreeSamplesContacts = () =>
    import ('@/views/GetFreeSamplesLines.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/Products',
    name: 'Products',
    component: Products
  },
  {
    path: '/CustomizedPrints',
    name: 'CustomizedPrints',
    component: CustomizedPrints
  },
  // {
  //   path: '/news',
  //   name: 'News',
  //   component: News
  // },
  // {
  //   path: '/NewsDetail',
  //   name: 'NewsDetail',
  //   component: NewsDetail
  // },
  {
    path: '/Certification',
    name: 'Certification',
    component: Certification
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },

  {
    path: '/get-free-samples',
    name: 'GetFreeSamples',
    component: GetFreeSamples
  },

  {
    path: '/get-free-samples-contacts',
    name: 'GetFreeSamples',
    component: GetFreeSamplesContacts
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
