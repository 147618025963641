<template>
	<div class="home-all">
		<div class="home-banner" @click="toAboutUs()">
			<swiper class="swiper" :options="swiperOption">
				<swiper-slide>
					<img src="@/assets/images/home/homeBanner.png" alt=""/>
				</swiper-slide>
			</swiper>
			<img class="banner-2" src="@/assets/images/home/banner-2.jpg" />
		</div>
		<!-- <div class="home-intro"> -->
			<!-- <div class="intro-left" > -->
				<!-- <div class="left-wrap"> -->
					<!-- <div class="title">HOT NEW PRODUCT!!! </div> -->
					<!-- <div class="text">We Provide Innovative Machineries to Mask Companies to Produce Medical Grade Masks with Precise Printing of any Colour, Logo and Image. Suitable for Brand Promotion, Large Events, Corporate gifts, Fashion Accessory, School Masks and More.</div> -->
					<!-- <div class="button" @click="toProducts()"> -->
						<!-- <div>Learn More</div> -->
					<!-- </div> -->
				<!-- </div> -->
			<!-- </div> -->
			<!-- <div class="intro-right" @click="toProducts()"> -->
				<!-- <swiper class="swiper" :options="swiperOptionSmall"> -->
					
					<!-- <swiper-slide> -->
						<!-- <img src="@/assets/images/home/products2.jpg" alt=""/> -->
						<!-- <img src="@/assets/images/home/right.jpg" alt=""/> -->
					<!-- </swiper-slide> -->
				<!-- </swiper> -->
			<!-- </div> -->
			<!-- <div class="clear"></div> -->
		<!-- </div> -->
		<!-- <p class="home-question">Why Customers Love Wearing Our Masks</p> -->
		<!-- <img v-if="$device.mobile" src="@/assets/images/home/whyMobile.png" alt="" class="answer-img"> -->
		<!-- <img v-else src="@/assets/images/home/whyPC.png" alt="" class="answer-img"> -->
		<!-- <img src="@/assets/images/home/under.jpg" alt="" class="answer-img"> -->

		<!-- 
			/**
			* hide
			*
			* edit by yusheng at 2021-06-02 21:15
			*/
			<img src="@/assets/images/home/whyLove.jpg" alt="" class="answer-img"> 
		-->

		<!-- <img src="@/assets/images/home/wear.png" alt="" class="wear-img"> -->
        <!-- <p class="why-text">Only Our Mask Machine Can <br/>
		Position The Print Exactly Where You Want it! <br/>
		Comes with its own 3D Modelling Preview Program
		</p> -->
		<!-- <img src="@/assets/images/home/machine.png" alt="" class="wear-img"> -->

		<!-- <img src="@/assets/images/home/new1.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new_2.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new_3.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new_4.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new_5.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new6.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/new7.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/add1.jpg" alt="" class="new-img">
		<img src="@/assets/images/home/add2.jpg" alt="" class="new-img"> -->

        <template
            v-for="(item, index) in showImgs1" 
            >
            <img 
                :key="'a-'+index"
                :src="require('@/assets/images/home/other/00'+item.url+'.jpg')" 
                :alt="item.url" class="new-img"
                v-if="item.jump.length <= 1" />

            <a :key="'a-'+index" :href="''+item.jump+''" target="_blank">
                <img 
                    :src="require('@/assets/images/home/other/00'+item.url+'.jpg')" 
                    :alt="item.url" class="new-img"
                    v-if="item.jump.length > 1" />
            </a>
        </template>
        
		

		<p class="example-title">Customized Prints</p>
		<Examples :headTop="headTop" />
	</div>
</template>

<script>
    import '@/assets/less/Home.less';
	import Examples from '@/components/Examples.vue';
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
    import 'swiper/css/swiper.css'
	export default {
		name: 'Home',
		components: {
			Examples,
			Swiper,
            SwiperSlide
		},
		props: [
			'headTop'
		],
		data() {
			return {
				swiperOption: {
					spaceBetween: 30,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					loop : false,
					autoplay: {
						delay: 3500,
						// stopOnLastSlide: false,
                        disableOnInteraction: false // 触摸后是否停止自动移动
					}
				},
				swiperOptionSmall: {
					spaceBetween: 30,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					loop : false,
					autoplay: {
						delay: 3500,
						// stopOnLastSlide: false,
                        disableOnInteraction: false // 触摸后是否停止自动移动
					},
				},
                showImgs1: [
                    { url: 1, jump: '' },
                    { url: 2, jump: '' },
                    { url: 3, jump: '' },
                    { url: 4, jump: '' },
                    { url: 5, jump: '' },
                    { url: 6, jump: '' },
                    { url: 7, jump: '' },
                    { url: 8, jump: '' },
                    { url: 9, jump: 'https://youtu.be/9XkOX4T6seg' },
                    { url: 10, jump: '' },
                    { url: 11, jump: 'https://youtu.be/1AWk7JUaWk4' },
                ]
			}
		},
		mounted() {},
		methods: {
			toAboutUs() {
				this.$router.push("/aboutUs");
			},
			toProducts() {
				this.$router.push("/products");
			}
		}
		
	}
</script>
