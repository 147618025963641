<template>
    <div :class="['examples-tabs', isFullHeader?'':'no-full']" ref="examplesTabsBox">
        <ul 
            :class="['tabs-all', examplesTabsPlaceholder.length>1?'ceiling-suction':'']"
            :style="[headTopStyle]"
            ref="examplesTabsHeader">
            <li v-for="(item, i) in tabs" :key="'tabCHeader'+item.id+i" :ref="'tabCHeader'+item.id"
                :class="['tab-each', currentCheckedId==item.id?'active':'']" @click="clickTab(item)">
                <div v-text="item.title"></div>
            </li>
        </ul>
        <div :class="[examplesTabsPlaceholder.length>1?'':'hide']" 
                v-html="examplesTabsPlaceholder"></div>
        <ul class="examples-concrete">
            <li class="concrete-each" v-for="(item, i) in tabs" :key="'tabCHeaderContent'+item.id+i" :ref="'tabCHeaderContent'+item.id">
                <h6 v-text="item.title" v-if="!isFullHeader"></h6>
                <img 
                    v-for="(imgItemUrl, imgI) in item.imgs.images" 
                    :key="'imgContent'+i+''+imgI"
                    :src="imgItemUrl" alt="" />
            </li>
        </ul>
    </div>
</template>
<script>
    import '@/assets/less/examples.less';
    export default {
        name: 'Examples',
        data() {
            return {
                currentCheckedId: -1,
                examplesTabsPlaceholder: "",
                headTopStyle: {
                }
            }
        },
        props: {
            headTop: {
                type: Number,
                default() {
                    return 0;
                }
            },
            tabs: {
                type: Array,
                default() {
                    return [
                            {id: 1, title:"Business Advertising", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/BusinessAdvertising/1.png'),
                                    require('@/assets/images/customerExamples/BusinessAdvertising/2.png'),
                                    require('@/assets/images/customerExamples/BusinessAdvertising/3.png'),
                                    require('@/assets/images/customerExamples/BusinessAdvertising/4.png'),
                                ]
                            }},
                            {id: 2, title:"Governments/ Organizations", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/GovernmentOrganisations/1.png'),
                                    require('@/assets/images/customerExamples/GovernmentOrganisations/2.png'),
                                ]
                            }},
                            {id: 3, title:"Large Events", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/LargeEvents/1.png'),
                                    require('@/assets/images/customerExamples/LargeEvents/2.png'),
                                    require('@/assets/images/customerExamples/LargeEvents/3.png'),
                                ]
                            }},
                            {id: 4, title:"Fashion Accessories", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/FashionAccessories/1.png'),
                                    require('@/assets/images/customerExamples/FashionAccessories/2.png'),
                                    require('@/assets/images/customerExamples/FashionAccessories/3.png'),
                                ]
                            }},
                            {id: 5, title:"Holiday Festival", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/HolidayFestivals.png'),
                                ]
                            }},
                            {id: 6, title:"Transportation", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/Transportation.png'),
                                ]
                            }},
                            {id: 7, title:"Schools and Universities", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/customerExamples/SchoolsandUniversities.png'),
                                ]
                            }},
                    ];
                }
            },
            isFullHeader: {
                type: Boolean,
                default() {
                    return true;
                }
            }
        },
        mounted() {
            // 页面滚动的监听回调
            window.addEventListener('scroll', this.handleScroll);
            // 监听dom渲染完成
            this.$nextTick(function() {
                this.handleScroll();
            });
        },
        destroyed() {
            // 销毁页面检测事件
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                const boxInfo = this.$refs.examplesTabsBox.getBoundingClientRect();
                const headerInfo = this.$refs.examplesTabsHeader.getBoundingClientRect();
                if (boxInfo.top - this.headTop <= 0) {
                    this.examplesTabsPlaceholder = 
                        "<div style='width: 100%; height: "+headerInfo.height+"px;'></div>";
                    this.$set(this.headTopStyle, 'top', this.headTop+'px');
                } else {
                    this.examplesTabsPlaceholder = "";
                    this.headTopStyle = {};
                }
                if (typeof this.tabs != 'object' || null == this.tabs || this.tabs.length < 1) {
                    return;
                } 
                let currentCheckedId = -1;
                let lastCheckedId = currentCheckedId;
                let lastBoxContent = null;
                let isGetSelected = false; // 是否已经找到选中的状态了
                for (const key in this.tabs) {
                    if (
                        !Object.hasOwnProperty.call(this.tabs, key) || 
                        (
                            typeof this.tabs[key].id != 'number' &&
                            typeof this.tabs[key].id != 'string'
                        )
                    ) {
                        continue;
                    }
                    const currentId = this.tabs[key].id;
                    const currentContent = this.$refs['tabCHeaderContent'+currentId];
                    if (typeof currentContent != 'object' || 
                        null == currentContent ||
                        typeof currentContent[0] != 'object' || 
                        null == currentContent[0] ||
                        typeof currentContent[0].getBoundingClientRect != 'function') {
                            continue;
                    }
                    const boxContent = currentContent[0].getBoundingClientRect();
                    lastCheckedId = currentId;
                    lastBoxContent = boxContent;
                    if (boxContent.top <= headerInfo.height && !isGetSelected) {
                        // 如果当前内容 它离顶部的距离小于等于tab高度
                        // 说明当前可能选中的是它, 对没错, 是可能
                        currentCheckedId = currentId;
                        continue;
                    }
                    // 如果当前内容 它离顶部的距离大于tab高度
                    // 说明它的上一个元素一定是被选中的
                    isGetSelected = true;
                }
                if (this.$device.mobile) {
                    if (lastBoxContent.bottom - window.screen.availHeight <= 100 && 
                        lastCheckedId - 1 == currentCheckedId) {
                        currentCheckedId = lastCheckedId;
                    }
                }
                this.currentCheckedId = currentCheckedId;
                const currentHeader = this.$refs['tabCHeader'+currentCheckedId];
                if (typeof currentHeader != 'object' || 
                    null == currentHeader ||
                    typeof currentHeader[0] != 'object' || 
                    null == currentHeader[0] ||
                    typeof currentHeader[0].getBoundingClientRect != 'function') {
                        return;
                }
                const tHeaderInfo = currentHeader[0].getBoundingClientRect();
                const wWidth = document.body.clientWidth;
                if (tHeaderInfo.x + tHeaderInfo.width >= wWidth) {
                    this.$refs.examplesTabsHeader.scrollLeft = 
                        this.$refs.examplesTabsHeader.scrollLeft + 
                            tHeaderInfo.x + tHeaderInfo.width - wWidth;
                } else {
                    if (tHeaderInfo.x <= 0) {
                        this.$refs.examplesTabsHeader.scrollLeft = 0;
                    }
                }
            },
            clickTab(item) {
                const currentId = item.id;
                const currentContent = this.$refs['tabCHeaderContent'+currentId];
                if (typeof currentContent[0] != 'object' || 
                    null == currentContent[0] ||
                    typeof currentContent[0].getBoundingClientRect != 'function') {
                        return;
                }
                const faultTolerant = 20;

                const headerInfo = this.$refs.examplesTabsHeader.getBoundingClientRect();
                const curentInfo = currentContent[0].getBoundingClientRect();
                if (curentInfo.top >= headerInfo.height) {
                    window.scrollBy(0, curentInfo.top - headerInfo.height + faultTolerant);
                    return;
                }
                return window.scrollBy(0, curentInfo.top - headerInfo.height + faultTolerant);
            }
        }
    }
</script>
