<template>
	<div id="app" :class="['containerBody', pageAdditionalClass]">
		<div id="nav" :class="[!isShowHeader?'hide':'']">
            <a class="login" href="http://shop.shieldmedicals.com/mobile/supplier.php?suppId=36693">Login</a>
			<ul class="mobile-icon" ref="pageHeadMenuRef" v-if="$device.mobile" @click="toggleMobileTab()">
				<li></li>
				<li></li>
				<li></li>
			</ul>
			<div class="nav-row" v-else>
                <router-link to="/">
                    <div>Home</div>
                </router-link> 
                <!-- <router-link to="/products">
                    <div>Products</div>
                </router-link> -->
                <router-link to="/CustomizedPrints">
                    <div>Customized Prints</div>
                </router-link>
                <!-- <router-link to="/News">
                    <div>News</div>
                </router-link> -->
                <router-link to="/Certification">
                    <div>Certification</div>
                </router-link>
                <router-link to="/aboutUs">
                    <div>About Us</div>
                </router-link>
                <router-link to="/ContactUs">
                    <div>Contact Us</div>
                </router-link>
			</div>
			<img src="~@/assets/logo.png" alt="logo" class="logo" @click="goHome" />
            <van-image
                @click="goFreeSamples"
                class="free-samples-click"
                :src="require('/src/assets/images/get-free-samples/get-free-samples-click.gif')"
            />
		</div>
        <template v-if="$device.mobile">
            <div class="transparent" v-if="curMobileNavRowRight==0" @click="closeMobileTab()"></div>
            <div :class="['nav-row']" ref="mobileNavRowWidthRef" :style="[mobileNavRowWidthStyle]">
                <router-link to="/" @click.native="closeMobileTab()">
                    <div>Home</div>
                </router-link>
                <!-- <router-link to="/products" @click.native="closeMobileTab()">
                    <div>Products</div>
                </router-link> -->
                <router-link to="/CustomizedPrints" @click.native="closeMobileTab()">
                    <div>Customized Prints</div>
                </router-link>
                <!-- <router-link to="/News">
                    <div>News</div>
                </router-link> -->
                <router-link to="/Certification" @click.native="closeMobileTab()">
                    <div>Certification</div>
                </router-link> 
                <router-link to="/aboutUs" @click.native="closeMobileTab()">
                    <div>About Us</div>
                </router-link>
                <router-link to="/ContactUs" @click.native="closeMobileTab()">
                    <div>Contact Us</div>
                </router-link>
            </div>

        </template>

        
		<div :style="[contentStyle]">
            <router-view :headTop="headTop" />
        </div>
	</div>
</template>

<script>
import '@/assets/less/mobile/common.less';
import Vue from 'vue';
import { Image as VanImage } from 'vant';

Vue.use(VanImage);

var navTimeCache = null;
export default {
    data() {
        return {
            pageAdditionalClass: "",
            headTop: 0,
            mobileNavRowInitRight: 0,  // 初始化的菜单离右边的具体
            curMobileNavRowRight: 0, // 当前离右边的距离
            mobileNavRowWidthStyle: {
            },
            isShowHeader: true,
            contentStyle: {}
        };
    },
    created() {
        this.checkDeviceInfo();
    },
    mounted() {
        this.rem();
        // 监听dom渲染完成
        this.$nextTick(function() {
            this.pageLoadingComplete();
        });
    },
    methods: {
        goHome() {
            this.$router.push({
                path: '/'
            });
        },
        goFreeSamples() {
            this.$router.push({
                path: '/get-free-samples',
                query: {
                    t: (new Date()).getTime()
                }
            });
        },
        setHeaderMarginTop() {
            this.$set(this.contentStyle, 'margin-top', this.headTop+'px');
        },
        /**
         * 检查设备信息
         */
        checkDeviceInfo() {
            let deviceClass = '';
            if (this.$device.mobile) {
                deviceClass += ' '+'mobileBody';
            } else {
                deviceClass += ' '+'pcBody';
            }
            if (this.$device.iphone) {
                deviceClass += ' '+'iphoneBody';
            } else if (this.$device.android) {
                deviceClass += ' '+'androidBody';
            }
            if (this.$device.wechat) {
                deviceClass += ' '+'wechatBody';
            } else if (this.$device.wechatMiniApp) {
                deviceClass += ' '+'wechatAppBody';
            }
            if (this.$device.iphoneX) {
                deviceClass += ' '+'iphoneXBody';
            }
            deviceClass = deviceClass.trim();
            this.pageAdditionalClass = deviceClass;
        },
        /**
         * rem兼容
         */
        rem() {
            if (!this.$device.mobile) {
                return;
            }
            const pageWidth = 750;
            (function() {
                const docEl = document.documentElement;
                const isIOS = window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                let dpr = isIOS ? Math.min(window.devicePixelRatio, 3) : 1;
                dpr = window.top === window.self ? dpr : 1;
                const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
                docEl.dataset.dpr = dpr;
                const recalc = function() {
                    let width = docEl.clientWidth;
                    if (width / dpr > pageWidth) {
                        width = pageWidth * dpr;
                    }
                    docEl.dataset.width = width;
                    docEl.dataset.percent = 100 * (width / pageWidth);
                    docEl.style.fontSize = 100 * (width / pageWidth) + 'px';
                };
                recalc();
                if (!window.addEventListener) return;
                window.addEventListener(resizeEvt, recalc, false);
            })();
        },
        closeMobileTab() {
            this.toggleMobileTab();
        },
        moveNav(clickType = 'show') {
            const that = this;
            const changePx = 30;

            let curRight = that.curMobileNavRowRight;
            
            if (clickType == 'show') {
                curRight += changePx;
                if (curRight >= 0) {
                    curRight = 0;
                }
                
            } else {
                curRight -= changePx;
                if (curRight <= 0 - that.mobileNavRowInitRight) {
                    curRight = 0 - that.mobileNavRowInitRight;
                }
            }
            that.curMobileNavRowRight = curRight;
            that.$set(that.mobileNavRowWidthStyle, 'right', that.curMobileNavRowRight+'px');
        },
        toggleMobileTab() {
            const that = this;

            let isMoveIng = '';
            if (that.curMobileNavRowRight == 0) {
                // 准备做消失事件
                isMoveIng = 'hide';
            } 
            if (that.curMobileNavRowRight < 0 && that.curMobileNavRowRight == 0 - that.mobileNavRowInitRight) {
                // 准备做显示事件
                isMoveIng = 'show';
            }
            if ('' == isMoveIng) {
                return;
            }
            that.moveNav(isMoveIng);

            function showNav() {
                if (that.curMobileNavRowRight >= 0) {
                    return;
                } 
                if (that.curMobileNavRowRight < 0 && 
                    that.curMobileNavRowRight <= 0 - that.mobileNavRowInitRight) {
                    return;
                }
                if (typeof navTimeCache != 'undefined' && null != navTimeCache) {
                    clearTimeout(navTimeCache);
                }
                navTimeCache = setTimeout(function() {
                    that.moveNav(isMoveIng);

                    showNav();
                }, 50);
            }
            showNav();
        },

        /**
         * 页面加载完成
         */
        pageLoadingComplete() {
            if (!this.$device.mobile) {
                return;
            }
            const headRef = this.$refs.pageHeadMenuRef.getBoundingClientRect();
            this.headTop = headRef.height;
            
            const navRef = this.$refs.mobileNavRowWidthRef.getBoundingClientRect();
            this.mobileNavRowInitRight = navRef.width;
            if (typeof this.mobileNavRowInitRight != 'number' || this.mobileNavRowInitRight <= 0) {
                return;
            }
            this.curMobileNavRowRight = 0 - this.mobileNavRowInitRight;
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
#app {
    font-family: "PingFangSC-Regular", Helvetica, Tahoma, Arial, Roboto, "Droid Sans", "Helvetica Neue", "Droid Sans Fallback", "Heiti SC", sans-self;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #303030;
}
</style>
